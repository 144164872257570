import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import _, { toInteger } from 'lodash';
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import { Avatar, Grid, ListItem, ListItemText, Paper, ListItemAvatar, List, CircularProgress} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Fade from "@material-ui/core/Fade";
import { InfoContainer, LoadingSkeletons } from "../../components";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import GetAppIcon from '@material-ui/icons/GetApp';
import Chip from '@material-ui/core/Chip';
import { history } from '../../config';
import StatusIndicator from "./components/StatusIndicator";
import NewComment from "./components/NewComment";
import { openGlobalSnackbar, fetchCRMCaseDetail, downloadAttachment, openCRMMessageDialog } from '../../actions';
import Typography from '@material-ui/core/Typography';
import saveAs from 'file-saver';
import marked from 'marked';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { deepOrange, grey } from '@material-ui/core/colors';
import ErrorIcon from '@material-ui/icons/Error';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    minHeight: "70vh",
    padding: 0
  },
  header: {
    textAlign: 'center',
  },
  title: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  table: {
    margin: "5px 0 0 20px",
    padding: "0 20px 0 0"
  },
  tableCell: {
    "&:hover": {
      backgroundColor: "rgba(1,1,1,0.0625)",
      cursor: "pointer",
    }
  },
  downloadButton: {
    "$tableCell:hover &": {
      opacity: "1.0",
    },
    opacity: "0.25",
    marginRight: "10px",
    marginTop: "5px",
  },
  firstChip: {
    margin: '0.5vh',
  },
  answerButton: {
    margin: "5px"
  },
  statusBarPending: {
    backgroundColor: "#FCBB6D",
    color: 'white'
  },
  statusBarAccepted: {
    backgroundColor: "#4AB19D",
    color: 'white'
  },
  statusBarDeclined: {
    backgroundColor: "#EF3D59",
    color: 'white'
  },
  orange : {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  expansionPanel: {
    backgroundColor: grey[200],
  },
  expansionPanelDetails: {
    backgroundColor: grey[100],
  }
});

const Attachment = ({name, date, fileName, error, errorText}) => {
  return(
    <ListItemText
      primary={name}
      secondary={<>
        {date + (fileName !== name ? ` - ${fileName}` : '')}
        {error &&
          <Grid container alignItems="center">
            <ErrorIcon color="error" style={{padding:2}}/>
            <Typography color="error">{errorText}</Typography></Grid>}
      </>}
    />
  )
};

class CRMDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attachmentsPerRow: 5,
      answerOpen: false, 
      errorSending: false,
      errorMessage: '',
      sendingMessage: false,
      message: '',
      downloadingAttachment: {},
    };
  }

  calculateWidth = () => {
    /* 448 és una unitat arbitrària per dividir els attachments en finestres més petites
      segons la mida d'una pantalla. Exemple: 1920 / 448 = 4
    */
    this.setState({
      attachmentsPerRow: Math.ceil(window.innerWidth / 448)
    });
  }

  componentDidMount(){
    window.addEventListener("resize", this.calculateWidth);
    if(_.get(this.props, 'caseData.id', false) !== toInteger(this.props.match.params.id)){
      this.props.fetchCRMCaseDetail(this.props.match.params.id)
      .then(
        ([statusCode,]) => {
          if (statusCode === 401) {
            this.props.openGlobalSnackbar(this.props.t('validation:error_fetching_data'), true);
          }
        }
      );
    }
  }
  
  componentWillUnmount(){
    window.removeEventListener("resize", this.calculateWidth);
  }

  downloadAttachment(attachment){
    this.setState({
      downloadingAttachment: {...this.state.downloadingAttachment, [attachment.id]: {downloading: true, error: false}}
    });
    this.props.downloadAttachment(
      this.props.match.params.id, 
      attachment.id
    ).then(
      response => {
        if(response[0] === 200){
          saveAs(response[1].content, response[1].file_name);
          this.setState({
            downloadingAttachment: {...this.state.downloadingAttachment, [attachment.id]: {downloading: false, error: false}}
          });
        }
        else{
          this.setState({
            downloadingAttachment: {...this.state.downloadingAttachment, [attachment.id]: {downloading: false, error: true}}
          });
        }
      }
    );
  }

  renderAttachments(){
    const { t, classes, caseData, userName } = this.props;
    const attachments = _.get(caseData, 'attachments');
    return(
      <div>
        <ExpansionPanel className={classes.expansionPanel}>
          <ExpansionPanelSummary 
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="h6">{t("common:crm_attachment_title")} ({attachments ? attachments.length : 0}) </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            <div className={classes.root}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div>
                    <List className={classes.table}>
                      {attachments && attachments.map((attachment, idx) => (
                        <ListItem
                          key={idx}
                          button
                          disabled={this.state.downloadingAttachment?.[attachment.id]?.downloading}
                          onClick={() => this.downloadAttachment(attachment)}
                        >
                          <ListItemAvatar>
                            {attachment.creator === 'ov' ? (
                              <Avatar className={classes.orange}>{userName[0]}</Avatar>
                            ) : (
                              <Avatar/>
                            )}
                          </ListItemAvatar>
                          <Attachment
                            name={attachment.name}
                            date={attachment.date}
                            fileName={attachment.file_name}
                            error={this.state.downloadingAttachment?.[attachment.id]?.error}
                            errorText={this.props.t('validation:error_fetching_data')}
                          />
                          <div>
                            {this.state.downloadingAttachment?.[attachment.id]?.downloading ?
                              <CircularProgress />
                            :
                              <GetAppIcon />
                            }
                          </div>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </Grid>
              </Grid>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }

  renderConversation() {
    const { t, classes, caseData } = this.props;
    const mails = _.get(caseData, 'mails');
    if (!_.isElement(mails)){
      return mails.map(
        (mail, ids) => {
          return (
            <Paper key={ids} style={{ padding: "10px 10px", marginTop: 20 }}>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  {
                    _.get(mail, 'from.customer', false) 
                    ?
                    <Avatar className={classes.orange}>{_.get(mail, 'from.name')[0]}</Avatar>
                    :
                    <Avatar />
                  }
                </Grid>
                <Grid item zeroMinWidth>
                  <h4 style={{ margin: 0, textAlign: "left" }}>
                    {
                      _.get(mail, 'from.customer', false) 
                      ?
                      _.get(mail, 'from.name')
                      :
                      <span>{t("common:administrator")}</span>
                    }
                  </h4>
                  <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: marked(_.get(mail, 'message')) }} />
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {_.get(mail, 'date')}
                  </p>
                </Grid>
              </Grid>
            </Paper>
          );
        }
      );
    } else {
      return null;
    }
  }

  renderAnswerBox() {
    const { t, classes, caseData } = this.props;
    const name = _.get(caseData, 'mails[0].from.name', 'undefined');
    const email = _.get(caseData, 'mails[0].from.email', 'undefined');

    const openAnswerButton = (
      <Button
        variant="contained"
        color="primary"
        className={classes.answerButton}
        startIcon={<QuestionAnswerIcon />}
        onClick={() => {this.props.openCRMMessageDialog()}}
      >
        {t("common:answer")}
      </Button>
    )

    return( 
      this.props.answerOpen 
      ? 
      <NewComment name={name} email={email}/> 
      : 
      openAnswerButton 
    );      
  }

  getCaseType () {
    switch (
      this.props.match.params.type || this.props.fetchedSection
      ) {
        case 'solicitudes':
          return 'SOL_CRM';
        case 'autoconsumo':
          return 'AUTOCONS';
        case "reclamacio":
          return "RECLAMACIO";
        case "evehicle":
          return "EVEHICLE";
        default:
          return 'SOL_CRM';
      }
  }

  returnButton() {
    const { classes, t } = this.props;
    return (
      <Button
        variant="contained"
        color="default"
        className={classes.button}
        startIcon={<ArrowBackIcon />}
        onClick={() => {history.push(`/solicitudes/${this.props.match.params.type}`)}}
      >
        {t("common:crm_go_back")}
      </Button>
    );
  }
  
  renderTitle(){
    const { classes, caseData } = this.props;
    return (
      <div>
        {_.get(caseData,'data.title')}
        <Chip 
            label={_.get(caseData, 'data.section')} 
            variant="outlined" 
            size="small"
            color="primary"

            className={classes.firstChip}
          />
          {_.get(caseData, 'data.category') &&
            <Chip
            label={_.get(caseData, 'data.category')} 
            variant="outlined"
            size="small"
            color="secondary"
            />
          }
      </div>
    );
  }

  render() {
    const { classes, t, caseData, loadingCase } = this.props;
    if(!loadingCase && caseData){
      return (
        <Fade in={true}>
            <InfoContainer
              maxWidth={"lg"}
              title={this.renderTitle()}
              subtitle={t("common:crm_detail_subtitle")}
              className={classes.container}
              actionButton={this.returnButton()}
            >
              {loadingCase ?
                <LoadingSkeletons fullHeight/>
                :
                <div>
                  <StatusIndicator status={caseData.status}/>
                  {this.renderAttachments()}
                  <Grid 
                    container 
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item xs={1}></Grid>
                    <Grid 
                      item
                      xs={10}
                    >
                      {this.renderConversation()}
                      {caseData.status?.state !== "done" && this.renderAnswerBox()}
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </div>
              }
            </InfoContainer>
        </Fade>
      );
    } else {
      return(
        <Fade in={true}>
            <InfoContainer
              maxWidth={"lg"}
              title={"Error"}
              subtitle={t("common:crm_error_no_info")}
              className={classes.container}
              actionButton={this.returnButton()}
            >
            </InfoContainer>
        </Fade>
      );
    }
  }
}

const mapDispatchToProps = {
  fetchCRMCaseDetail,
  downloadAttachment,
  openCRMMessageDialog,
  openGlobalSnackbar
};

const mapStateToProps = state => {
  return {
    caseData: _.get(state, 'CRMCases.case', false),
    loadingCase: _.get(state, 'CRMCases.loadingCRMCase', true),
    isSignedIn: state.user.isSignedIn,
    userName: state.user.name,
    answerOpen: _.get(state, 'CRMCases.case.answerOpen', false),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(['common', 'validation']),
  withRouter
)(CRMDetail);
